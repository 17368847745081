import React, { Component } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

@observer
class MyTextArea extends Component {
    
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.name, event.target.value);
  }

  render() {
    return (
      <>
        <h5 htmlFor={this.props.id}>
          {this.props.visible && (this.props.label || this.props.name)}
        </h5>
        <textarea
          className={this.props.className}
          disabled={this.props.disabled}
          id={this.props.id}
          name={this.props.name}
          onChange={this.onChange}
          value={this.props.value}
          rows={this.props.rows}
          placeholder={this.props.placeholder}
        ></textarea>
      </>
    );
  }
}

/*
<div className="form-group">
*/

MyTextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
};

MyTextArea.defaultProps = {
  className: "form-control",
  rows: "8",
};

export default MyTextArea;
