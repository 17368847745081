import { observable, action } from "mobx";

class PageStore {
	//Info pageStore
	@observable len = 20;
	@observable pageArray = [];
	@observable page = 0;
	@observable totalPages = 1;
	@observable totalData;
	@observable maxPages = 20;
	@observable searchDirection = true;
	@observable sortDirection = 1;


	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action("update attribute") updateProperty = (attrName, value) => {		
		this[attrName] = value;		
	};

	@action("Change Direction") changeDirection = ()=>{
		this.searchDirection = !this.searchDirection;
	}

	@action("Restart Direction") restartDirection = ()=>{
		this.searchDirection = true;
	}

	@action("Set PageArray") setPageArray = (dataArray)=>{
		this.pageArray = dataArray;
		this.totalPages = this.pageArray.length
	}

	@action("Set length") setLen = (newLen)=>{
		this.len = newLen;
	}

	@action("Set maxPages") setMax = (num)=>{
		this.maxPages = num;
	}

	@action("Set page") setPage = (newPage)=>{		
		this.page = newPage;
	}

	@action("Next page") nextPage = (num)=>{
		this.page += num;
	}

	@action("Previous page") previousPage = (num)=>{
		this.page -= num;

	}

	@action("Set totalData") setTotalData = (num)=>{
		this.totalData = num;
	}
}

export default PageStore;
