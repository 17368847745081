import React, { Component } from "react";
import { observer } from "mobx-react";

@observer
class MyRadioButton extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.name, event.target.checked);
  }
  render() {
    return (
      <span key={"div_" + this.props.id}>
        <input
          type="radio"
          key={this.props.id}
          id={this.props.id}
          name={this.props.name}
          disabled={this.props.disabled}
          style={this.props.style}
          onChange={this.onChange}
          value={this.props.value}
          checked={this.props.checked}
          className={this.props.className}
        />
        <label
          key={"label_" + this.props.id}
          htmlFor={this.props.id}
          className={this.props.labelClass}
        >
          {this.props.labelValue}
        </label>
      </span>
    );
  }
}

export default MyRadioButton;
