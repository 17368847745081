import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ProcessTracker from "./ProcessTrackerForWA";
import { withTranslation } from "react-i18next";

@inject("mapListStore", "navigationStore", "requestStore")
@observer
class GroupCard extends Component {
  // constructor (props) {
  //       super(props)
  //       this.onClick = this.onClick.bind(this)
  //   }

  render() {
    const { t } = this.props;
    return (
      <div key={this.props.groupAd._id + this.props.index}>
        <div
          className="card card-ads"
          id={this.props.groupAd._id}
          onMouseEnter={(e) => {
            this.props.mapListStore.hoverCard = true;
            // let t0 = performance.now();
            this.props.mapListStore.changeCirclesColor(this.props.groupAd);
            // let t1 = performance.now();
            //console.log("PAINTING TIME IS:... ", t1 - t0, " milliseconds");
          }}
          onMouseLeave={(e) => {
            this.props.mapListStore.hoverCard = false;
            // let t0 = performance.now();
            this.props.mapListStore.switchCircleColor("repaint");
            // let t1 = performance.now();
            //console.log("REPAINTING TIME IS:... ", t1 - t0, " milliseconds");
          }}
        >
          <div
            className={
              this.props.mapListStore.listMode
                ? "row card-grid"
                : "row card-list"
            }
            id={this.props.groupAd._id + "-type"}
          >
            {this.props.mapListStore.listMode ? (
              <div
                className="col-md-3 card-img"
                id={this.props.groupAd._id + "-card-img"}
                img={this.props.groupAd.thumbnail}
              >
                <img
                  className="card-img-top"
                  src={this.props.groupAd.thumbnail}
                  alt={this.props.groupAd._id}
                />
              </div>
            ) : null}
            <div
              className={
                this.props.mapListStore.listMode
                  ? "col-md-8 card-info"
                  : "col-md-10 card-info"
              }
              id={this.props.groupAd._id + "-card-info"}
            >
              <div
                className={
                  this.props.mapListStore.listMode
                    ? "card-info-top-grid"
                    : "card-info-top-list"
                }
              >
                <p
                  style={{
                    display: "inline-block",
                    padding: "2px 4px 0px 0px",
                  }}
                >
                  {this.props.groupAd.name}
                </p>

                <ProcessTracker group={this.props.groupAd}></ProcessTracker>

                <div className="group-info">
                  <div className="group-info-child">
                    <div className="websites">
                      <p>
                        <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">
                              {t("wa.tooltips.address")}
                            </Tooltip>
                          }
                        >
                          <svg
                            className="svg-icon-card-wa"
                            data-name="fas fa-file-signature"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 71.73 63.69"
                          >
                            <path
                              className="cls-1"
                              d="M4.06,59.89q0-2,0-3.93V52c0-1.34,0-2.67,0-4V44q0-2,0-3.93V36c0-1.33,0-2.67,0-4V28.09c0-1.33,0-2.67,0-4v-4c0-1.34,0-2.67,0-4V12.16a11.6,11.6,0,0,1,.12-2A3.14,3.14,0,0,1,5.93,8.36a3.49,3.49,0,0,1,1.36-.21H31.92c0,1.11,0,2.22,0,3.33a1.74,1.74,0,0,0,0,.68v3.92a.65.65,0,0,0-.05.39c0,1,0,2,0,3a1.58,1.58,0,0,0,0,.65v4c-.36,2.67,1.36,4.29,3.94,3.94h4a.75.75,0,0,0,.39,0h2.91a2,2,0,0,0,.72,0h3.92a.78.78,0,0,0,.4,0h3.63c0,1.32,0,2.64,0,4l0,1.87L49.74,36c-.66.58-1.27,1.2-1.87,1.84-.76.7-1.48,1.43-2.18,2.17-.61.56-1.2,1.14-1.76,1.74-.76.71-1.48,1.44-2.2,2.18l-1.81,1.81A31.12,31.12,0,0,0,37.68,48L36,49.55a.41.41,0,0,0-.11.3c0,.7,0,1.4,0,2.1,0,1.34,0,2.67,0,4,0,1.17,0,2.34,0,3.51,0,.31-.09.41-.38.35a2.26,2.26,0,0,1-1-1.08,2.73,2.73,0,0,0-.4-.56A4.67,4.67,0,0,0,32,56.68a5.28,5.28,0,0,0-4-.11,3.27,3.27,0,0,0-1.16.59c-.19.14-.28.13-.35-.11A6.17,6.17,0,0,0,26,55.87a6.69,6.69,0,0,0-.38-1.25c-.31-.89-.55-1.81-.93-2.67a2,2,0,0,0-.74-1,2.92,2.92,0,0,0-4,0,1.8,1.8,0,0,0-.67.93c-.56,1.33-.95,2.72-1.39,4.09l-1,2.92a1.37,1.37,0,0,1-1.43,1c-.48,0-1,0-1.45,0a2,2,0,0,0-2,1.76,2,2,0,0,0,1.61,2.18,9.55,9.55,0,0,0,2.39,0A5.21,5.21,0,0,0,20,61.57a4.76,4.76,0,0,0,.79-1.69L22,56.29l1.23,3.61A15.77,15.77,0,0,0,24,62a2,2,0,0,0,2.17,1.86A2.12,2.12,0,0,0,28,62.25c.3-.43.47-.93.73-1.37a1.37,1.37,0,0,1,2.46,0A5.38,5.38,0,0,0,32,62a5.21,5.21,0,0,0,3.94,1.85c1.34,0,2.67,0,4,0h7.94l4,0c0,.14,0,.29,0,.43,0,1.19,0,2.38,0,3.57a13.51,13.51,0,0,1-.09,1.68,3,3,0,0,1-2.32,2.21,3.12,3.12,0,0,1-.68,0H7.25a4.1,4.1,0,0,1-1-.08c-.21-.1-.42-.18-.62-.29a3.25,3.25,0,0,1-1.42-1.68,14.86,14.86,0,0,1-.1-1.89c0-1.33,0-2.67,0-4Z"
                              transform="translate(-4.06 -8.14)"
                            />
                            <path
                              className="cls-1"
                              d="M35.94,8.16a3.46,3.46,0,0,1,2.64.65c.32.28.6.59.91.89l.37.34c.68.73,1.38,1.44,2.11,2.11s1.28,1.34,2,2,1.28,1.34,2,2l2,2,2,2c.33.34.65.69,1,1a3.46,3.46,0,0,1,.91,1.54,5.86,5.86,0,0,1,.06,1.45H35.93c0-1.33,0-2.66,0-4V12.15Z"
                              transform="translate(-4.06 -8.14)"
                            />
                            <path
                              className="cls-1"
                              d="M74,28.09c.36.37.74.74,1.07,1.13a3.63,3.63,0,0,1,.66,1.28,3.59,3.59,0,0,1-1.23,3.33c-.76.69-1.46,1.46-2.18,2.19a2.68,2.68,0,0,0-.51.5c-.25.34-.48.27-.71,0a5.55,5.55,0,0,0-.49-.48l-2.79-2.78c-.41-.41-.82-.81-1.22-1.22-.91-.95-1.84-1.87-2.79-2.78a5.55,5.55,0,0,0-.48-.49c-.27-.21-.32-.41-.05-.65a2.56,2.56,0,0,0,.52-.52c.78-.77,1.59-1.52,2.34-2.32a3.36,3.36,0,0,1,3.27-1.1,3.84,3.84,0,0,1,1.8,1.18c.19.2.4.38.61.57C72.54,26.66,73.27,27.38,74,28.09Z"
                              transform="translate(-4.06 -8.14)"
                            />
                            <path
                              className="cls-1"
                              d="M68.33,40a2.68,2.68,0,0,0-.5.51Q66.09,42.21,64.42,44a3,3,0,0,0-.59.6c-1.17,1.09-2.26,2.25-3.38,3.39l-.57.57c-1.16,1.13-2.3,2.27-3.42,3.44a3.24,3.24,0,0,0-.58.59c-1.16,1.12-2.3,2.25-3.41,3.42a3.25,3.25,0,0,0-.61.62c-1.12,1.07-2.2,2.19-3.28,3.3l-.71,0c-1.31,0-2.63,0-3.94,0h-4V51.3L43.29,48a5,5,0,0,0,.65-.65c1.15-1.09,2.26-2.21,3.37-3.35l.57-.56c1.17-1.09,2.29-2.23,3.4-3.38a3.3,3.3,0,0,0,.59-.59C53,38.32,54.18,37.18,55.31,36a3.47,3.47,0,0,0,.58-.57c1.17-1.12,2.31-2.26,3.44-3.42l.57-.57c.2-.25.37-.24.58,0s.43.42.64.63c.85.89,1.73,1.76,2.62,2.61a18.23,18.23,0,0,0,1.39,1.39c.85.89,1.72,1.77,2.62,2.62l.54.56C68.65,39.64,68.65,39.64,68.33,40Z"
                              transform="translate(-4.06 -8.14)"
                            />
                          </svg>
                        </OverlayTrigger>
                      </p>
                      <div className="card-websites">
                        {this.props.groupAd.address ? (
                          <>{this.props.groupAd.address}</>
                        ) : (
                          <>{"-"}</>
                        )}
                      </div>
                    </div>

                    {/* */}
                    <br></br>
                    <div className="websites">
                      <p>
                        <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">
                              {t("wa.tooltips.url")}
                            </Tooltip>
                          }
                        >
                          <svg
                            className="fas fa-paperclip"
                            style={{width:"1rem", fill:"#808080"}}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z"
                            ></path>
                          </svg>
                        </OverlayTrigger>
                      </p>
                      <div className="card-websites">
                        {this.props.groupAd.url &&
                        this.props.groupAd.url !== "-" ? (
                          this.props.groupAd.url.length <= 40 ? (
                            <a
                              href={
                                this.props.groupAd.url.includes("http://") ||
                                this.props.groupAd.url.includes("https://")
                                  ? this.props.groupAd.url
                                  : "http://" + this.props.groupAd.url
                              }
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {this.props.groupAd.url}
                            </a>
                          ) : (
                            <a
                              href={
                                this.props.groupAd.url.includes("http://") ||
                                this.props.groupAd.url.includes("https://")
                                  ? this.props.groupAd.url
                                  : "http://" + this.props.groupAd.url
                              }
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {this.props.groupAd.url.substring(0, 40) + "..."}
                            </a>
                          )
                        ) : (
                          <>{"-"}</>
                        )}
                      </div>
                    </div>

                    <br></br>

                    <div className="websites">
                      <p>
                        <OverlayTrigger
                          delay={{ show: 500, hide: 100 }}
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip">
                              {t("wa.tooltips.websites")}
                            </Tooltip>
                          }
                        >
                          <svg
                            className="svg-icon-card-wa"
                            data-name="fas fa-globe"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 61.91 61.88"
                          >
                            <path
                              className="cls-1"
                              d="M44,10.93l1.18,1.23c.1.14.18.28.28.42a20.51,20.51,0,0,1,2.29,4c.53,1.16,1,2.37,1.4,3.58l1.13,4c.16.82.33,1.64.47,2.46A7.26,7.26,0,0,1,51,28.11H48a6.69,6.69,0,0,0-1.34,0c-.87,0-1.74-.05-2.61,0H40a7.39,7.39,0,0,0-1.41,0c-.87,0-1.74-.05-2.61,0H32.05a.9.9,0,0,0-.43,0l-2.65,0c.06-.37.12-.74.19-1.11.18-.94.37-1.89.55-2.83l1.13-4c.41-1.08.81-2.17,1.22-3.25a3,3,0,0,0,.36-.77,24.33,24.33,0,0,1,2.39-3.94l1.26-1.3A6.38,6.38,0,0,1,40,9.12a5.86,5.86,0,0,1,2.48.71A9.9,9.9,0,0,1,44,10.93Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M52,11.55l1.55.7L56,13.61a30.55,30.55,0,0,1,6.35,5.08,33,33,0,0,1,4.2,5.44l1.41,2.58L68.5,28c-.15.19-.38,0-.55.13h-4a19.64,19.64,0,0,0-2.17,0,11.76,11.76,0,0,0-1.78,0H56l-1-.07c0-.2,0-.39-.08-.58-.22-1.12-.37-2.25-.66-3.35a36.26,36.26,0,0,0-1-4A27.33,27.33,0,0,0,52,16.54l-.18-.45a23.91,23.91,0,0,0-1.94-3.93c-.28-.57-.69-1.08-1-1.68A31.54,31.54,0,0,1,52,11.55Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M28,11.55a27.16,27.16,0,0,1,3.08-1.07,16.45,16.45,0,0,0-1,1.59,22.62,22.62,0,0,0-2,4,.62.62,0,0,0-.11.32,25.15,25.15,0,0,0-1.28,3.69,2.43,2.43,0,0,0-.13.38,30.07,30.07,0,0,0-.9,3.63c-.2.67-.3,1.36-.44,2.05a16,16,0,0,1-.39,1.95H24a6.66,6.66,0,0,0-1.34,0c-.89,0-1.79-.05-2.68,0H16.07a21.11,21.11,0,0,0-2.25,0,11.78,11.78,0,0,0-1.78,0h-.48c-.1-.14,0-.26.06-.37a9.53,9.53,0,0,0,.44-1l1.41-2.58a3.28,3.28,0,0,0,.2-.31,31,31,0,0,1,5.85-6.92A33.29,33.29,0,0,1,24,13.59l2.58-1.42A15.05,15.05,0,0,0,28,11.55Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M44,69.25A6.35,6.35,0,0,1,40,71a6.43,6.43,0,0,1-4-1.81c-.38-.39-.76-.79-1.13-1.19a23.48,23.48,0,0,1-2.49-4.1,1.52,1.52,0,0,0-.23-.5c-.47-1.12-.89-2.27-1.31-3.41l-1.09-3.93c0-.12,0-.24-.06-.36-.27-1.21-.5-2.43-.7-3.65L32,52c1.32,0,2.63,0,4,0h4c1.34,0,2.68,0,4,0H48L51,52c0,.22-.07.45-.12.67-.21,1.11-.42,2.22-.64,3.33L49.17,60a26.9,26.9,0,0,1-4,8Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M28,68.58a12.63,12.63,0,0,0-1.45-.66L24,66.54l-.71-.45a31.91,31.91,0,0,1-9.4-9.4,2.29,2.29,0,0,0-.45-.65c-.27-.49-.51-1-.81-1.47a6,6,0,0,0-.6-1.08A7.53,7.53,0,0,0,11.48,52l.57,0h4c1.32,0,2.63,0,4,0h4L25,52c.22,1.34.44,2.68.76,4a35.55,35.55,0,0,0,1,3.94c.08.25.14.51.22.76a21.14,21.14,0,0,0,1.09,3,.45.45,0,0,0,.1.28,25.13,25.13,0,0,0,2,4l1,1.59a.69.69,0,0,1-.42-.08Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M52,68.59l-2.69.94c-.13,0-.25.13-.39.07h0c.31-.51.69-1,1-1.52a23.56,23.56,0,0,0,2-4.09,1.14,1.14,0,0,0,.17-.43A26.44,26.44,0,0,0,53.29,60a31.62,31.62,0,0,0,1-3.94c.34-1.32.53-2.67.75-4L56,52q2,0,4,0h3.94c1.33,0,2.67,0,4,0l.59,0L68,53.41,66.5,56.05a1.34,1.34,0,0,0-.11.14,30.51,30.51,0,0,1-7.49,8.32c-1,.75-2,1.44-3,2.09L53.26,68Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M36,48H32l-3.61,0c-.07-.77-.12-1.54-.2-2.31a13,13,0,0,0-.12-1.4v-.22l-.1-4c0-.29,0-.58,0-.87,0-2.39.16-4.76.4-7.14H51.52a30.74,30.74,0,0,1,.3,3.33c.07,1.56.11,3.12.16,4.68,0,1.13,0,2.26-.09,3.38-.07,1.53-.16,3-.36,4.57H36Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M56,40.08c0-1.34,0-2.67-.12-4s-.17-2.67-.29-4H68l1.92,0a31.42,31.42,0,0,1,1.08,8A34.62,34.62,0,0,1,70.67,44a36.54,36.54,0,0,1-.79,4L68,48H56l-.42,0c.12-1.33.27-2.66.29-4S56,41.4,56,40.08Z"
                              transform="translate(-9.04 -9.12)"
                            />
                            <path
                              className="cls-1"
                              d="M24.15,36.07c-.1.8-.06,1.61-.11,2.42,0,.53,0,1.06,0,1.59s0,1.07,0,1.6c.05.78,0,1.56.11,2.34a2.8,2.8,0,0,0,0,.29L24.39,48H12.05l-1.93,0c-.37-1.31-.57-2.66-.83-4-.1-1.31-.24-2.61-.25-3.93s.15-2.67.26-4,.46-2.68.82-4l1.93,0H24.44C24.32,33.4,24.17,34.73,24.15,36.07Z"
                              transform="translate(-9.04 -9.12)"
                            />
                          </svg>
                        </OverlayTrigger>
                      </p>
                      <div className="card-websites">
                        {this.props.groupAd.provider &&
                          Object.entries(this.props.groupAd.provider).map(
                            (value) => {
                              return (
                                <div key={this.props.groupAd.id + value}>
                                  <OverlayTrigger
                                    delay={{ show: 500, hide: 100 }}
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        {value[0].toLowerCase()}
                                      </Tooltip>
                                    }
                                    key={this.props.groupAd.id + value}
                                  >
                                    <img
                                      key={this.props.groupAd.id + value}
                                      className="ad-platform-logo"
                                      src={
                                        "/logos/" +
                                        value[0].toLowerCase() +
                                        ".png"
                                      }
                                      alt={value[0]}
                                      onClick={(e) => {
                                        this.props.navigationStore.openInNewTab(
                                          value[1]
                                        );
                                      }}
                                    />
                                  </OverlayTrigger>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.props.mapListStore.listMode
                  ? "col-md-1 card-buttons-grid"
                  : "col-md-2 card-buttons-list"
              }
              id={this.props.groupAd._id + "-card-btns"}
            ></div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

export default withTranslation()(GroupCard);
