const axios = require("axios");

class RequestStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }


  getRegion = async (query) => {
    try {
      let res = await axios.get("/api/region/" + query, {});
      ////await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  searchRegionsByName = async (text) => {
    try {
      text = text.replace("(", "")
      text = text.replace(")", "")
      let res = await axios.get("/api/region", {
        params: {
          f: "contains/$/" + text,
          mySearch: true,
          projection: "f$h$j",
          limit: 10,
        },
      });
      //await this.logoutIfSessionHasExpired(res.data);
      return res.data;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  searchBiggestRegion = async (admNumber) => {
    try {
      let res = await axios.get("/api/region/IncGeo/?h=adm" + admNumber);
      //await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getPlatforms = async () => {
    try {
      let res = await axios.get("/api/hired/platforms/", {});
      //await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (err) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getAllStates = async (group) => {
    try {
      const res = await axios.get("/api/hired/concrete", {
        params: {
          group: group
        },
      });
      //await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getRegionInfoForSearch = async (value) => {
    try {
      let res = await axios.get("/api/region/regionSearch/?f=" + value.name, {
        params: {
          parentName: value.parentName,
          projection: "q$f$h$j"
        }
      });
      ////await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getlistEstablishmentsInMap = async (focusedRegion, boundaries, activities, isOpen, covid, alphabetic, hasOffers, language, lastName, associated) => {
    try {
      let queryParams =
        "/api/establishment/map?regionName=" + focusedRegion._id
      if (!!boundaries) {
        queryParams += "&boundaries=" + JSON.stringify(boundaries);
      }
      let res = await axios.get(queryParams, {
        params: {
          activities: activities,
          isOpen: isOpen,
          covid: covid,
          alphabetic: alphabetic,
          hasOffers: hasOffers,
          language: language,
          skipObject: lastName,
          skipPriority: associated
        }
      });
      ////await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getEstablishmentById = async (id) => {
    try {
      let res = await axios.get("/api/establishment/" + id, {
        params: {
          projection: "b$c$d$e$f$g$h$i$j$k$l$m",
        },
      });
      //await this.logoutIfSessionHasExpired(res.data);
      return res;
    } catch (err) {
      console.log("An error ocurred while executing the action requested");
    }
  }
}
export default RequestStore;
