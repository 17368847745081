// import React, {Component} from 'react'
// import {observer} from 'mobx-react'
// import { observable, action } from "mobx";
// import PropTypes from 'prop-types';
// import MyInput from '../basicUI/MyInput'
// import Modal from './modal.css'

class UIStore {
  constructor (rootStore) {
    this.rootStore = rootStore;
  }
}



export default UIStore;
