import React, { Component } from "react";
import { observer } from "mobx-react";
// import PropTypes from 'prop-types';
import MySuggestionElem from "./MySuggestionElem";

@observer
class MySuggestions extends Component {
  render() {
    return (
      <ul>
        {this.props.suggestions.map((sug, i) => (
          <MySuggestionElem
            searchOption={this.props.searchOption}
            key={sug._id || sug.id || sug.hostNickname || sug.licenseCode}
            suggestion={sug}
            fieldsToShow={this.props.fieldsToShow}
            onClick={this.props.onClick}
            index={i}
          />
        ))}
      </ul>
    );
  }
}

export default MySuggestions;
