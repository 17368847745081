import React, { Component } from "react";
import { observer } from "mobx-react";

@observer
class MyInput extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.name, event.target.value);
  }

  render() {
    return (
      <>
        <label htmlFor={this.props.id}>
          {!this.props.visible ? this.props.label || this.props.name : null}
        </label>
        <input
          className={this.props.className}
          disabled={this.props.disabled}
          id={this.props.id}
          name={this.props.name}
          onChange={this.onChange}
          type={this.props.type}
          value={this.props.value}
          style={this.props.style}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
        />
      </>
    );
  }
}

MyInput.defaultProps = {
  type: "text",
  value: "",
  autoComplete: "off"
};

export default MyInput;
