import React, { Component } from "react";
import { observer, inject } from "mobx-react";
//import PropTypes from 'prop-types';
import MyInput from "../basicUI/MyInput";
import MyRadioButton from "../basicUI/MyRadioButton";
import MyTextArea from "../basicUI/MyTextArea";
import MyButton from "../basicUI/MyButton";
import "../App.css";
import i18n from "../../i18n";

@inject("mapListStore")
@observer
class MyModal extends Component {
  onCancel = () => {
    this.props.processStore.closing = false;
    this.props.processStore.saving = false;
    this.props.groupAdsStore.closing = false;
    this.props.groupAdsStore.saving = false;
    this.props.processStore.notFinished = false;
  };

  onClose = () => {
    if (this.props.groupAdsStore.openModal) {
      this.props.groupAdsStore.closed()
      //this.select()
    }
    else this.props.processStore.closed();
  };

  onClosing = (show) => {
    if (this.props.groupAdsStore.openModal) {
      this.props.groupAdsStore.close(show)
      //this.select()
    }
    else this.props.processStore.close(show);
  };

  onAddTask = () => {
    this.props.processStore.createNewTask();
  };

  saveProcess = () => {
    this.props.processStore.addNewProcess();
  };

  savingProcess = () => {
    if (this.props.groupAdsStore.openModal) {
      this.props.groupAdsStore.saving = true;
    }
    else this.props.processStore.saving = true;
  };

  saveTask = () => {
    this.props.processStore.addNewTask();
    this.props.processStore.showHideTask2 = false;
  };
  saveCurrentTask = (task) => {
    this.props.processStore.editTask(task);
  };

  deleteTask = (task) => {
    this.props.processStore.deleteTask(task);
  };

  editTask = (task) => {
    this.props.processStore.showHideTask2 = true;
    task.editing = true;
  };

  saveCurrentProcess = () => {
    this.props.processStore.addExistentProcess();
  };

  saveCurrentReport = (task, show) => {
    this.props.processStore.addExistentReport(task, show);
  };

  select = () => {
    let elem2 = document.getElementById("inputGroupSelect01")
    if (elem2) {
      for (let j = 0; j < elem2.length; j++){
        var option = elem2.options[j];
        if (option.value === this.props.groupAdsStore.statesGroup.value) {
          option.selected = true
        }
        else option.selected = false
      }
    }
  }

  //closing = true;
  //   onClosing2 = (e) => {
  //     this.props.processStore.close2();
  // }


  //style={{ top: this.props.groupAdsStore.mouseP.y, left: this.props.groupAdsStore.mouseP.x }}

  NotesModalForGA = () => {
    /* MouseOver del in process */
    let sh = this.props.mapListStore.showModal;
    const showHide = sh
      ? "modalHover display-block"
      : "modalHover display-none";
    const showAll = this.props.mapListStore.openeningModal
      ? "display-block"
      : "display-none";
    //&#x2713; tick
    //&#x2715; cross
    //&#x25C9; eye

    return (
      <div className={showHide} style={{ top: this.props.mapListStore.mouseP.length > 0 ? JSON.stringify(this.props.mapListStore.mouseP[1]+80)+"px" : null, left: this.props.mapListStore.mouseP.length > 0 ? JSON.stringify(this.props.mapListStore.mouseP[0])+"px" : null}}>
        <div className={showAll}>
          <section className="modal-main">
            <div className="card container">
              <form className="justify-content-center">
                  <div key={this.props.mapListStore.establishment.id}>
                    <span
                        style={{
                            color: this.props.mapListStore.establishment.open ? "green" : "red",
                            position: "relative",
                            top: "-2px",
                        }}
                    >
                        {this.props.mapListStore.establishment.open ? '\u2713' : '\u2715'}
                    </span> 
                      {i18n.t("wa.open")}
                      <br/>
                      <span
                        style={{
                            color: this.props.mapListStore.establishment.offer ? "green" : "red",
                            position: "relative",
                            top: "-2px",
                        }}
                    >
                        {this.props.mapListStore.establishment.offer ? '\u2713' : '\u2715'}
                    </span>
                      {i18n.t("wa.hasOffers")}
                      <br/>
                      <span
                        style={{
                            color: this.props.mapListStore.establishment.covid ? "green" : "red",
                            position: "relative",
                            top: "-2px",
                        }}
                    >
                        {this.props.mapListStore.establishment.covid ? '\u2713' : '\u2715'}
                    </span>
                      {i18n.t("wa.safetyMeasures")}
                      <br/>

                      {/* {i18n.t(""wa.safetyMeasures"") + ": " +
                    <p> {this.props.mapListStore.establishment.offer ? <span
                                style={{
                                  color: "green",
                                  position: "relative",
                                  top: "-2px",
                                }}
                              >
                                &#x25C9;
                              </span> : null} </p>
                    {this.props.mapListStore.establishment.open} 
                    {this.props.mapListStore.establishment.covid} 
                      )
                  </div>
                {/* {this.props.groupAdsStore.statesGroup && this.props.groupAdsStore.statesGroup.name ? 
                      (
                        <div key={this.props.groupAdsStore.statesGroup.name}>
                            <p>
                              {i18n.t("ga.status.status") + ": " + i18n.t("ga.status.statusOptions."+this.props.userStore.getUser().group+"."+this.props.groupAdsStore.statesGroup.name+"."+this.props.groupAdsStore.statesGroup.name+"Write")}
                              <br/>
                          {i18n.t("ga.status.situation") + ": "} {this.props.groupAdsStore.statesGroup.value && this.props.groupAdsStore.statesGroup.value!== null ? i18n.t("ga.status.statusOptions."+this.props.userStore.getUser().group+"."+this.props.groupAdsStore.statesGroup.name+"."+this.props.groupAdsStore.statesGroup.value) : "-"}
                              <br/>
                              {i18n.t("ga.status.remark") +": "}
                              <span
                                style={{
                                  color: this.props.groupAdsStore.statesGroup.message && this.props.groupAdsStore.statesGroup.message.notes && this.props.groupAdsStore.statesGroup.message.notes.length > 0 ? "green" : "grey",
                                  position: "relative",
                                  top: "-2px",
                                }}
                              >
                                &#x25C9;
                              </span>{" "}
                              <br/>
                              {this.props.groupAdsStore.workingUserAd === this.props.userStore.getUser().username ? "" : i18n.t("mymodal.user") + ": " + this.props.groupAdsStore.workingUserAd}
                            </p>
                        </div>
                      ) */}
                      </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    );
  };

  render() {
    return this.NotesModalForGA()
  }
}

export default MyModal;
