import NavigationStore from "./NavigationStore";
import RequestStore from "./RequestStore";
import MapListStore from "../components/Map/MapListStore";
import PageStore from "./PageStore";
import SearchStore from "../components/Search/SearchStore";
import UIStore from "../components/basicUI/UIStore";
import i18n from "../i18n"

class RootStore {
  constructor() {
    this.navigationStore = new NavigationStore(this);
    this.requestStore = new RequestStore(this);
    this.mapListStore = new MapListStore(this);
    this.pageStore = new PageStore(this);
    this.searchStore = new SearchStore(this);
    this.uiStore = new UIStore(this);
    this.t = i18n.t;

    this.version = "1.0.9.1"
  }

  getVersion = () => {
    return this.version;
  };
}

export default RootStore;
