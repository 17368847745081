import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Map from "./Map";
import ListAds from "./ListAds";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from "react-i18next";

@inject("mapListStore")
@observer
class MapDiv extends Component {
  keyUpEvent = (event) => {
    event.preventDefault();
    if (this.props.mapListStore.screenBoolean && event.keyCode === 27) {
      this.props.mapListStore.activeRightBtn("full");
      this.props.mapListStore.switchScreenBoolean();
    }
  };

  async componentDidMount() {
    try {
      // let {t} = this.props;
      // document.title = t("wa.title")
      document.title = "Restart Catalunya";
      this.props.mapListStore.activateFilters();
      document.addEventListener("keyup", this.keyUpEvent);
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyUpEvent);
  }

  render() {
    const { t } = this.props;
    let name = "";
    if (
      this.props.mapListStore.focusedRegion &&
      this.props.mapListStore.focusedRegion.name
    )
      name = this.props.mapListStore.focusedRegion.name;
    let numEstablishments = "";
    if (
      this.props.mapListStore.establishments &&
      this.props.mapListStore.establishments.length
    )
      numEstablishments =
        this.props.mapListStore.establishments.length.toString();
    if (
      this.props.mapListStore.establishments.length >=
      this.props.mapListStore.maxEstablishments
    )
      numEstablishments += "+";
    return (
      <>
        <main role="main" className="container-fluid MapDiv">
          <div className="row">
            <div
              className={
                "alert alert-danger" +
                (!!this.props.mapListStore.showAlert
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="danger-alert-wa"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.mapListStore.updateProperty("showAlert", false);
                }}
              >
                {" "}
                x{" "}
              </button>
            </div>
            <div
              className={
                "alert alert-success" +
                (!!this.props.mapListStore.confirmAlert
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="succes-alert-wa"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.mapListStore.updateProperty("confirmAlert", false);
                }}
              >
                {" "}
                x{" "}
              </button>
            </div>
            <div
              className={
                "alert alert-danger" +
                (!!this.props.mapListStore.showAlert2
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="danger-alert-wa2"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.mapListStore.updateProperty("showAlert2", false);
                }}
              >
                {" "}
                x{" "}
              </button>
            </div>
            <div className="col-md-6">
              <div className="row filter-content">
                <div className="col-md-10 area-list-filter" name="filter-btn">
                  <div className="div-content">
                    <MyButton
                      id="flt-isOpen"
                      className={
                        "btn btn-outline-info flt-isOpen" +
                        (this.props.mapListStore.isOpen === "true"
                          ? " fltActive"
                          : "")
                      }
                      disabled={
                        this.props.mapListStore.canIContinue ? "false" : "true"
                      }
                      onClick={async () => {
                        this.props.mapListStore.canIContinue = false;
                        let button = document.getElementById("flt-isOpen");
                        if (button.className.includes("fltActive")) {
                          button.className = button.className.replace(
                            " fltActive",
                            ""
                          );
                          await this.props.mapListStore.updateUrlFilter(
                            "open",
                            false
                          );
                        } else {
                          button.className += " fltActive";
                          await this.props.mapListStore.updateUrlFilter(
                            "open",
                            true
                          );
                        }
                        await this.props.mapListStore.getEstablishmentsInMap();
                        this.props.mapListStore.canIContinue = true;
                      }}
                      type="button"
                      text={t("wa.isOpen")}
                    />
                  </div>

                  <div className="div-content">
                    <MyButton
                      id="flt-hasOffers"
                      type="button"
                      className={
                        "btn btn-outline-info flt-hasOffers" +
                        (this.props.mapListStore.hasOffers === "true"
                          ? " fltActive"
                          : "")
                      }
                      disabled={
                        this.props.mapListStore.canIContinue ? "false" : "true"
                      }
                      text={t("wa.hasOffers")}
                      onClick={async () => {
                        this.props.mapListStore.canIContinue = false;
                        let button = document.getElementById("flt-hasOffers");
                        if (button.className.includes("fltActive")) {
                          button.className = button.className.replace(
                            " fltActive",
                            ""
                          );
                          this.props.mapListStore.updateUrlFilter(
                            "offer",
                            false
                          );
                        } else {
                          button.className += " fltActive";
                          this.props.mapListStore.updateUrlFilter(
                            "offer",
                            true
                          );
                        }
                        await this.props.mapListStore.getEstablishmentsInMap();
                        this.props.mapListStore.canIContinue = true;
                      }}
                    />
                  </div>

                  <div className="div-content">
                    <MyButton
                      id="flt-safetyMeasures"
                      type="button"
                      className={
                        "btn btn-outline-info flt-safetyMeasures" +
                        (this.props.mapListStore.covid === "true"
                          ? " fltActive"
                          : "")
                      }
                      disabled={
                        this.props.mapListStore.canIContinue ? "false" : "true"
                      }
                      text={t("wa.safetyMeasures")}
                      onClick={async () => {
                        this.props.mapListStore.canIContinue = false;
                        let button =
                          document.getElementById("flt-safetyMeasures");
                        if (button.className.includes("fltActive")) {
                          button.className = button.className.replace(
                            " fltActive",
                            ""
                          );
                          this.props.mapListStore.updateUrlFilter(
                            "covid",
                            false
                          );
                        } else {
                          button.className += " fltActive";
                          this.props.mapListStore.updateUrlFilter(
                            "covid",
                            true
                          );
                        }
                        await this.props.mapListStore.getEstablishmentsInMap();
                        this.props.mapListStore.canIContinue = true;
                      }}
                    />
                  </div>

                  <div className="div-content">
                    <MyButton
                      id="flt-alphabeticOrder"
                      type="button"
                      className={
                        "btn btn-outline-info flt-alphabeticOrder" +
                        (this.props.mapListStore.alphabetic === "true"
                          ? " fltActive"
                          : "")
                      }
                      disabled={
                        this.props.mapListStore.canIContinue ? "false" : "true"
                      }
                      text={
                        <>
                          <svg
                            className="fas fa-sort-alpha-down-alt"
                            style={{ width: "1rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path
                              fill="currentColor"
                              d="M176 352h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.36 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352zm112-128h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-56l61.26-70.45A32 32 0 0 0 432 65.63V48a16 16 0 0 0-16-16H288a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h56l-61.26 70.45A32 32 0 0 0 272 190.37V208a16 16 0 0 0 16 16zm159.06 234.62l-59.27-160A16 16 0 0 0 372.72 288h-41.44a16 16 0 0 0-15.07 10.62l-59.27 160A16 16 0 0 0 272 480h24.83a16 16 0 0 0 15.23-11.08l4.42-12.92h71l4.41 12.92A16 16 0 0 0 407.16 480H432a16 16 0 0 0 15.06-21.38zM335.61 400L352 352l16.39 48z"
                            ></path>
                          </svg>
                        </>
                      }
                      onClick={async () => {
                        this.props.mapListStore.canIContinue = false;
                        let button = document.getElementById(
                          "flt-alphabeticOrder"
                        );
                        if (button.className.includes("fltActive")) {
                          button.className = button.className.replace(
                            " fltActive",
                            ""
                          );
                          this.props.mapListStore.updateUrlFilter(
                            "alphabetic",
                            false
                          );
                        } else {
                          button.className += " fltActive";
                          this.props.mapListStore.updateUrlFilter(
                            "alphabetic",
                            true
                          );
                        }
                        await this.props.mapListStore.getEstablishmentsInMap();
                        this.props.mapListStore.canIContinue = true;
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-2 area-list-mode">
                  <MyButton
                    id="view-mode"
                    name={t("wa.tooltips.view")}
                    type="button"
                    onClick={() => {
                      this.props.mapListStore.toggleList();
                    }}
                    className="btn btn-outline-info active grid-view"
                    text={
                      <>
                        <img
                          className="svg-icon-view-mode"
                          src="./IconesSVGPlataforma/list.svg"
                          alt="ViewM"
                        />
                      </>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row filter-content" id="map-filter">
                <div
                  className="col-md-4 map-filter-btn-left "
                  id="map-filter-btn-left"
                >
                  <MyButton
                    id="flt-full"
                    type="button"
                    name={t("wa.tooltips.fullscreen")}
                    onClick={() => {
                      this.props.mapListStore.activeRightBtn("full");
                      this.props.mapListStore.switchScreenBoolean();
                    }}
                    className="btn btn-outline-info map"
                    text={
                      <>
                        <svg
                          className="svg-icon-filter-right-map"
                          data-name="fas fa-compress"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 56 57"
                        >
                          <path
                            className="cls-1"
                            d="M20,20H30a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2H15a2.94,2.94,0,0,0-3,3V31a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2Z"
                            transform="translate(-12 -12)"
                          />
                          <path
                            className="cls-1"
                            d="M60,20V31a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V15a2.94,2.94,0,0,0-3-3H50a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H60"
                            transform="translate(-12 -12)"
                          />
                          <path
                            className="cls-1"
                            d="M12,50V66a2.94,2.94,0,0,0,3,3H30a2,2,0,0,0,2-2V63a2,2,0,0,0-2-2H20V50a2,2,0,0,0-2-2H14A2,2,0,0,0,12,50Z"
                            transform="translate(-12 -12)"
                          />
                          <path
                            className="cls-1"
                            d="M60,50V61H50a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H66s2,0,2-3V50a2,2,0,0,0-2-2H62A2,2,0,0,0,60,50Z"
                            transform="translate(-12 -12)"
                          />
                        </svg>
                      </>
                    }
                  />
                </div>
                <div
                  className="col-md-8 map-filter-btn-right"
                  id="map-filter-btn-right"
                >
                  {numEstablishments !== "" && name !== "" ? (
                    <>
                      {" "}
                      <b>{numEstablishments}</b> {t("wa.establishments")}{" "}
                      <b>{name}</b> {t("wa.arround")}
                    </>
                  ) : name !== "" ? (
                    <>
                      {" "}
                      <b>{0}</b> {t("wa.establishments")} <b>{name}</b>{" "}
                      {t("wa.arround")}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <ListAds />
            {<Map match={this.props.match} />}
          </div>
        </main>
      </>
    );
  }
}
export default withTranslation()(MapDiv);
