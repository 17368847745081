import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types';

@observer
class InputField extends Component {

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  render() {
    return (
      <div className="form-group">
        <label htmlFor={this.props.id}>{this.props.label}</label>
        <input
          className={this.props.className}
          id={this.props.id}
          name={this.props.name}
          onChange={this.onChange}
          type={this.props.type}
          value={this.props.value}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          autoComplete={this.props.autoComplete}
        />
      </div>
    )
  }
}

InputField.propTypes = {
  onChange: PropTypes.func.isRequired
}

InputField.defaultProps = {
  className: "form-control",
  type: 'text',
  autoComplete: 'on'
}

export default InputField;