import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from "react-i18next";

@inject("mapListStore", "navigationStore", "requestStore")
@observer
class GroupCard extends Component {
  overInProcess = async (e, idGA) => {
    let x = e.pageX;
    let y = e.pageY;
    await this.props.mapListStore.openGATasks(
      idGA.hasOffers,
      idGA.isOpen,
      idGA.hasSafetyMeasures,
      x,
      y,
      idGA._id
    );
  };

  onLeaves = async (idGA) => {
    setTimeout(() => {
      this.props.mapListStore.closeModal();
    }, 250);
    await this.props.mapListStore.closeModal();
  };

  render() {
    let open = this.props.group.isOpen === true;
    let offers = this.props.group.hasOffers === true;
    let covid = this.props.group.hasSafetyMeasures === true;
    return (
      <div
        className="group-info-status"
        style={{ position: "absolute", right: 0 }}
      >
        <MyButton
          id={"status_id" + this.props.group._id}
          type="button"
          onMouseOver={(e) => {
            this.overInProcess(e, this.props.group);
          }}
          onMouseLeave={(e) => {
            this.onLeaves(this.props.group);
          }}
          text={
            <>
              <svg
                className="fas fa-info-circle"
                style={{width:"1rem", fill:"#808080"}}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                ></path>
              </svg>
            </>
          }
          className={
            open
              ? offers && covid
                ? "all"
                : offers && !covid
                ? "noCovid"
                : !offers && covid
                ? "noOffer"
                : "noCovidAndOffers"
              : "noOpen"
          }
        ></MyButton>
      </div>
    );
  }
}

export default withTranslation()(GroupCard);
