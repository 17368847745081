import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import MyButton from "./basicUI/MyButton";
import Search from "./Search/Search";
import "./App.css";
import { observer, inject } from "mobx-react";
import backgroundESP from '../assets/img/spain.png'
import backgroundCAT from '../assets/img/cat2.png'
import backgroundFR from '../assets/img/france2.png'
import backgroundENG from '../assets/img/uk2.png'

import logo from "../assets/icons/logo_AV.svg";

@inject("rootStore")
@observer
class Navbar extends Component {
  // PER CONTROLAR EL LOGIN I LOGOUT DES D'UN ALTRE TAB DEL NAVEGADOR
  checkOldValues() {
    let url = this.props.rootStore.navigationStore.history.location.search
    let index = url.search("activity")
    if (index !== -1) {
      let ef = url.split("&")
      for (let j in ef) {
        if (ef[j].includes("activity")){
          let ad = ef[j].split("$")
          for (let p = 0; p < ad.length; ++p) {
            if (p === 0 && document.getElementById(ad[p].substring(9)+"Check")) document.getElementById(ad[p].substring(9)+"Check").checked = true
            else if (p !== 0 && document.getElementById(ad[p]+"Check")) document.getElementById(ad[p]+"Check").checked = true
          } 
        }
      }
    }
  }

  componentDidMount() {
    window.localStorage.setItem(
      "REQUESTING_SHARED_CREDENTIALS",
      Date.now().toString()
    );
    this.checkOldValues()
    window.localStorage.removeItem("REQUESTING_SHARED_CREDENTIALS");
  }

  
  save = () => {
    let valuesToSave = []
    let activity = ""
    if (document.getElementById("hotelCheck").checked) valuesToSave.push("hotel")
    if (document.getElementById("restaurantCheck").checked) valuesToSave.push("restaurant")
    if (document.getElementById("campsiteCheck").checked) valuesToSave.push("campsite")
    if (document.getElementById("ruralHouseCheck").checked) valuesToSave.push("ruralHouse")
    if (document.getElementById("touristApartmentCheck").checked) valuesToSave.push("touristApartment")
    if (document.getElementById("mountainHutCheck").checked) valuesToSave.push("mountainHut")
    if (document.getElementById("apartHotelCheck").checked) valuesToSave.push("apartHotel")
    for (let i=0; i<valuesToSave.length; ++i) {
      activity += valuesToSave[i]
      if (i+1 < valuesToSave.length) activity += "$"
      // else activity += "&"
    }
    let url = this.props.rootStore.navigationStore.history.location.search
    let newUrl = ""
    let index = url.search("activity")
    if (index !== -1) {
      let ef = url.split("&")
      for (let i= 0; i < ef.length; ++i) {
        if (ef[i].includes("activity") && i+1 < ef.length && ef[i+1] !== "") newUrl += "activity=" + activity + "&"
        else if (ef[i].includes("activity")) newUrl += "activity=" + activity
        else if (ef[i] !== "" && i+1 < ef.length && ef[i+1] !== "") newUrl += ef[i] + "&"
        else if (ef[i] !== "") newUrl += ef[i]
      }
      this.props.rootStore.navigationStore.push(newUrl)
      //this.props.rootStore.navigationStore.reload()
    }
    else {
      let newUrl = url + "&activity="+activity
      this.props.rootStore.navigationStore.push(newUrl)
      //this.props.rootStore.navigationStore.reload() 
    }
    let vals = JSON.parse(JSON.stringify(valuesToSave))
    this.props.rootStore.mapListStore.activities = vals
    let button = document.getElementById("flt-license")
    if (button) {
      if (button.className.includes(" fltActive")) button.className = button.className.replace(" fltActive", "")
      else button.className += " fltActive"
      let mod = document.getElementById("license-form")
      if (mod) {
        if (mod.className.includes("display-none")) mod.className = mod.className.replace("display-none", "display-block")
        else if (mod.className.includes("display-block")) mod.className = mod.className.replace("display-block", "display-none")
      }
    }
    this.props.rootStore.mapListStore.getEstablishmentsInMap()
  }

  delete = () => {
    let url = this.props.rootStore.navigationStore.history.location.search
    let newUrl = ""
    let index = url.search("activity")
    if (index !== -1) {
      let ef = url.split("&")
      for (let i = 0; i < ef.length; ++i) {
        if (ef[i].includes("activity") && i+1 < ef.length && ef[i+1] !== "") newUrl += "&"
        else if (ef[i].includes("activity")) newUrl += ""
        else if (ef[i] !== "" && i+1 < ef.length && !ef[i+1].includes("activity")) {
          newUrl += ef[i] + "&"
        }
        else if (ef[i] !== "") {
          newUrl += ef[i]}
      }
      this.props.rootStore.navigationStore.push(newUrl)
      this.props.rootStore.mapListStore.activities = []
      document.getElementById("hotelCheck").checked = false
      document.getElementById("restaurantCheck").checked = false
      document.getElementById("campsiteCheck").checked = false
      document.getElementById("ruralHouseCheck").checked = false
      document.getElementById("touristApartmentCheck").checked = false
      document.getElementById("mountainHutCheck").checked = false
      document.getElementById("apartHotelCheck").checked = false
      this.props.rootStore.mapListStore.getEstablishmentsInMap()
      
      //this.props.rootStore.navigationStore.reload()
    }
    let button = document.getElementById("flt-license")
    if (button) {
      if (button.className.includes(" fltActive")) button.className = button.className.replace(" fltActive", "")
      else button.className += " fltActive"
      let mod = document.getElementById("license-form")
      if (mod) {
        if (mod.className.includes("display-none")) mod.className = mod.className.replace("display-none", "display-block")
        else if (mod.className.includes("display-block")) mod.className = mod.className.replace("display-block", "display-none")
      }
    }
  }

  saveSomething = () => {
    return document.getElementById("hotelCheck").checked || document.getElementById("restaurantCheck").checked || document.getElementById("campsiteCheck").checked
      || document.getElementById("ruralHouseCheck").checked || document.getElementById("touristApartmentCheck").checked || document.getElementById("mountainHutCheck").checked || document.getElementById("apartHotelCheck").checked
  }

  render() {
    const { t, i18n } = this.props;
    const changeLanguage = async (lng) => {
      await i18n.changeLanguage(lng);
      // document.title = t("wa.title");
      this.props.rootStore.mapListStore.createIDiv(true, lng)
    };
    /**  TODO RECUPERAR L'ID DE LA SESSIONSTORE**/
    return (
      <div className="navBar">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          {/* <NavLink className="navbar-brand" to="/area"> */}
            <img src={logo} width="50" alt="" onClick = {(e) => {
              //this.props.rootStore.navigationStore.push("/area")
              //this.props.rootStore.navigationStore.reload()
              }
              } 
            />
          {/* </NavLink> */}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarToggler">
            <div className="huha col-md-4 mb-3">
              <Search />
            </div>
            <div className="huha col-md-6 mb-3"> 
              <MyButton
                id="flt-license"
                type="button"
                className={
                  "btn btn-outline-info flt-license"
                }
                text={t("dropdown.dropdown")}
                onClick={() => {
                  let button = document.getElementById("flt-license")
                  if (button) {
                    if (!button.className.includes(" fltActive")) button.className += " fltActive"
                    else button.className = button.className.replace(" fltActive", "")
                    let mod = document.getElementById("license-form")
                    if (mod) {
                      if (mod.className.includes("display-none")) mod.className = mod.className.replace("display-none", "display-block")
                      else if (mod.className.includes("display-block")) mod.className = mod.className.replace("display-block", "display-none")
                    }
                  }
                }}
                />
                <div style={{ zIndex: "10000" }}
                  id="license-form"
                  className={
                    "card license-form" +
                    (document.getElementById("flt-license") && document.getElementById("flt-license").className === "flt-license fltActive"
                    ? " display-block"
                    : " display-none")
                  }
                >
                <form>
                  <input
                    type="checkbox"
                    id= "hotelCheck"
                    style = {{margin: ".4rem"}}
                  />
                  <label>
                    {t("dropdown.hotel")}
                  </label>
                  <br/>
                      <input
                        type="checkbox"
                        id= "restaurantCheck"
                        style = {{margin: ".4rem"}}
                      />
                      <label>
                      {t("dropdown.restaurant")}
                      </label>
                      <br />
                      <input
                        type="checkbox"
                        id= "campsiteCheck"
                        style = {{margin: ".4rem"}}
                      />
                      <label>
                      {t("dropdown.campsite")}
                      </label>
                      <br/>
                      <input
                        type="checkbox"
                        id= "ruralHouseCheck"
                        style = {{margin: ".4rem"}}
                      />
                      <label>
                      {t("dropdown.ruralHouse")}
                      </label>
                      <br/>
                      <input
                        type="checkbox"
                        id= "touristApartmentCheck"
                        style = {{margin: ".4rem"}}
                      />
                      <label>
                      {t("dropdown.touristApartment")}
                      </label>
                      <br/>
                      <input
                        type="checkbox"
                        id= "mountainHutCheck"
                        style = {{margin: ".4rem"}}
                      />
                      <label>
                        {t("dropdown.mountainHut")}
                      </label>
                      <br/>
                      <input
                        type="checkbox"
                        id= "apartHotelCheck"
                        style = {{margin: ".4rem"}}
                      />
                      <label>
                        {t("dropdown.apartHotel")}
                      </label>
                      </form>
                      <hr />
                      <MyButton
                        id="button-filter-delete-license"
                        type="button"
                        className="btn btn-outline-danger license"
                        text={t("wa.delete")}
                        onClick={() =>
                          this.delete()
                        }
                      />
                      <MyButton
                        id="button-filter-save-license"
                        type="button"
                        className="save btn btn-info"
                        text={t("wa.save")}
                        onClick={() =>
                          this.saveSomething() ? this.save() : null
                        }
                        disabled={() =>
                          this.saveSomething()
                        }
                      />
                    </div>
              </div>                    
              <div>
                          <img className="icon-login" onClick={(e) => changeLanguage("ca")} src={backgroundCAT} alt="" width="25%"/>
                          <img className="icon-login" onClick={(e) => changeLanguage("es")} src={backgroundESP} alt="" width="25%"/>
                          <img className="icon-login" onClick={(e) => changeLanguage("fr")} src={backgroundFR} alt="" width="25%"/>
                          <img className="icon-login" onClick={(e) => changeLanguage("en")} src={backgroundENG} alt="" width="25%"/>
              </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default withTranslation()(Navbar);
