import React, { Component, Suspense } from "react";
import { Route } from "react-router-dom";
import { Router } from "react-router";
import { Provider, observer } from "mobx-react";
import RootStore from "../stores/RootStore";
import MapDiv from "../components/Map/MapDiv";
import Navbar from "./Navbar";

import "../App.css";
import "../assets/css/custom.css";


@observer
class AppInitializer extends Component {
  render() {
      const rootStore = new RootStore();
      return (
        <div className="App">
          <div>
            <Provider
              rootStore={rootStore}
              navigationStore={rootStore.navigationStore}
              requestStore={rootStore.requestStore}
              dashboardStore={rootStore.dashboardStore}
              mapListStore={rootStore.mapListStore}
              adStore={rootStore.adStore}
              pageStore={rootStore.pageStore}
              searchStore={rootStore.searchStore}
              uiStore={rootStore.uiStore}
              groupAdsStore={rootStore.groupAdsStore}
            >
              <Router history={rootStore.navigationStore.history}>
                <Navbar />
                <div>
                  <Route path="/area" component={MapDiv} />
                  <Route exact path="/" component={MapDiv} />
                </div>
              </Router>
            </Provider>
          </div>
        </div>
      );
    
  }
}

export default function App() {
  return (
    <Suspense fallback="loading">
      <AppInitializer />
    </Suspense>
  );
}
